















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import camelCaseKeys from 'camelcase-keys'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  components: {
    TrashCan16,
    PasswordConfirmField,
    ValidationObserver
  }
})
export default class ServiceMessages extends Vue {
  @Prop({ default: 'service' }) kind!: ServiceMessageKind
  messages = []
  messageModalIsOpened = false
  selectedMessage = {
    message: {
      subject: '',
      message: ''
    }
  }

  form: Record<string, any> = {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  created () {
    if (this.kind === 'create') {
      axios.get('/services/messages')
        .then(response => {
          this.messages = camelCaseKeys(response.data.data, { deep: true })
        })
    } else if (this.kind === 'service') {
      this.fetchServiceMessages()
    }
  }

  fetchServiceMessages () {
    axios.get(`/service/${this.$route.params.id}/messages`)
      .then(response => {
        this.messages = camelCaseKeys(response.data.data, { deep: true })

        this.$emit('hasMessages', this.messages.some((message: any) => !message.viewedAt))
      })
  }

  viewMessage (id: string) {
    axios.get(`/service/${this.$route.params.id}/messages/${id}`)
      .then(response => {
        this.selectedMessage = response.data.data
        this.messageModalIsOpened = true
      })
  }

  archiveMessage (id: string) {
    axios.delete(`/service/${this.$route.params.id}/messages/${id}`, { data: this.form })
      .then(() => {
        this.fetchServiceMessages()
        this.messageModalIsOpened = false
      }).catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }
}
